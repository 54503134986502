import {Modal, Text} from '@atoms/index';
import Image from "@atoms/Image";
import React from "react";
import texts from "@molecules/Footer/V2/en.json";
import { cn } from '@ui/helpers/utils';

const DownloadAppQrCode = ({
    show,
    onClose,
    isIos =false,
    hideTitle=false
}) => {
    return (
        <Modal
            show={show}
            onClose={onClose}
            roundedClass={'rounded-3xl'}
            width="hidden md:block md:w-[543px]"
            className="!px-0 !pt-0 !pb-0"
            hideTitle={true}
            children={
                <>
                    <div className="relative px-4 py-4">
                        <div className="flex flex-col items-center w-full px-4 py-1">
                            {hideTitle ? null : (
                                <div className="flex flex-row items-center gap-x-2">
                                    <Image
                                        src={
                                            isIos
                                                ? '/images/footer/app_store_logo.svg'
                                                : '/images/footer/google_play_logo.svg'
                                        }
                                        alt="store"
                                        className={`w-[28px] h-[28px]`}
                                    />
                                    <Text
                                        className={`h5-semibold text-gray-800`}
                                        content={
                                            isIos
                                                ? texts?.AppleAppStore
                                                : texts?.GooglePlayStore
                                        }
                                    />
                                </div>
                            )}
                            <div
                                className={cn(
                                    'w-[218px] h-[1px] bg-secondary-500 mt-3 mb-5', {
                                        "hidden" :hideTitle
                                    }
                                )}
                            />
                            <Image
                                src={
                                    isIos
                                        ? '/images/footer/qr_ios.png'
                                        : '/images/footer/qr_android.png'
                                }
                                alt="qr-code"
                                width="164"
                                height="164"
                            />
                            <Text
                                className={`p4-medium text-gray-500 mt-3`}
                                content={texts?.ScanTheQrCode}
                            />
                        </div>
                        <div
                            className={"absolute right-4 top-4"}
                            onClick={onClose}
                        >
                            <Image
                                src={'/images/footer/close.svg'}
                                alt="close"
                                className={"w-6 h-6"}/>
                        </div>
                    </div>
                </>
            }
        />
    );
};

export default DownloadAppQrCode;
