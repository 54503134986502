import React, { useMemo, useRef, useState } from 'react';
import AvatarSection from './AvatarSection';
import ExpandNavigationItem from './ExpandNavigationItem';
import RmElement from '@molecules/BottomNavigation/RmElement';
import MenuItemsWithAuth from './MenuItemsWithAuth';
import { Image, Text } from '@ui/atoms';
import useOutsideClick from '@hooks/useOutsideClick';
import { gaEventNameEnum } from '@helpers/enums/gaEventNameEnum';
import { triggerCustomEvent } from '@helpers/miscelleanous';
import { addQueriesToPath, removeQueriesFromUrlIfExist } from '@helpers/utils';
import { useRouter } from 'next/router';
const PREVIOUS_PAGE_STATUS_STATIC = 'static';

const NavigationPanel = ({
    menu,
    menuOpen,
    height,
    rmDetails,
    avatar,
    name,
    isStaticPage,
    logout,
    handleMenu,
    kycStatus
}) => {
    const navigationRef = useRef();
    const [showChildren, setShowChildren] = useState(rmDetails ? 'Relationship Manager' : '');
    useOutsideClick(navigationRef, () => menuOpen && handleMenu());
    const router = useRouter();

    const updateReferAndEarnLink = (referAndEarn, isStaticPage) => {
        if (referAndEarn.length === 0) return referAndEarn;
    
        const [firstLink] = referAndEarn;
    
        if (isStaticPage) {
            firstLink.link = addQueriesToPath({
                path: firstLink.link,
                queryParamObject: { previous: PREVIOUS_PAGE_STATUS_STATIC },
            });
        } else {
            firstLink.link = removeQueriesFromUrlIfExist({
                url: firstLink.link,
                queryParamObject: { previous: PREVIOUS_PAGE_STATUS_STATIC },
            });
        }
    
        return referAndEarn;
    };

    const filterMenu = (menuItems, isStaticPage) => {
        const excludeNames = ["Dashboard", "Opportunities", "altSafe", "Become a Partner"];
    
        let referAndEarn = menuItems.filter(item => item?.name === "Refer & Earn" && item?.showWhenLoggedIn);
        referAndEarn = updateReferAndEarnLink(referAndEarn, isStaticPage);
        const filteredItems = menuItems.filter(item => !excludeNames.includes(item.name) && item?.showWhenLoggedIn && item?.name !== "Refer & Earn");
        const knowledgeCentre = menuItems.filter((item) => item?.name === "Knowledge Centre");
        if (isStaticPage || router.query?.previous === PREVIOUS_PAGE_STATUS_STATIC) {
            return [...referAndEarn, ...filteredItems];
        } else {
            return [...referAndEarn, ...knowledgeCentre];
        }
    };

    const menuItems = useMemo(() => filterMenu(menu, isStaticPage), [menu, isStaticPage]);

    return (
        <div
            className={`${
                menuOpen ? 'mobile-menu-visible block' : 'mobile-menu-hidden hidden'
            } fixed top-12 md:top-16 right-0 z-100 mobile-menu min-width-full w-full items-end bg-basicBlack/40`}
        >
            <div
                className="navigation-panel relative flex flex-col justify-between gap-4 max-w-[400px]  w-[80%] pb-16 bg-basicWhite ml-auto mobile-menu-content px-3 overflow-auto scroll-vertical-width-0 animate__slideInRight animate__animated"
                style={{ height }}
                ref={navigationRef}
            >
                <div className="w-full flex flex-col gap-4 pt-3">
                    <AvatarSection avatar={avatar} name={name} kycStatus={kycStatus} />
                    {rmDetails && (
                        <ExpandNavigationItem
                            icon='/images/header/relationship-manager-icon.svg'
                            field={{ name: 'Relationship Manager', class: 'p5-medium text-gray-500' }}
                            className="flex items-center p-3 rounded-xl flex-col"
                            showChildren={showChildren}
                            setShowChildren={setShowChildren}
                            gTag={gaEventNameEnum.CLICKED_RM_SIDENAV_MOBILE_WEB_NAVIGATION}
                        >
                            <RmElement
                                rmDetails={rmDetails}
                                className="pt-3 border-t border-gray-200 mt-3 flex flex-col gap-2 w-full"
                                whatsAppIcon="/images/whatsapp-white.svg"
                                buttonType="primary"
                                gTag={gaEventNameEnum.CLICKED_CHAT_WITH_RM_SIDENAV_MOBILE_WEB_NAVIGATION}
                            />
                        </ExpandNavigationItem>
                    )}
                    <MenuItemsWithAuth 
                        menuItems={menuItems} 
                        showChildren={showChildren}
                        setShowChildren={setShowChildren}
                        handleMenu={handleMenu}
                    />
                </div>
                <div className="flex items-center gap-3 mt-6 md:mb-4 cursor-pointer px-3" onClick={() => {
                    logout();
                    triggerCustomEvent(gaEventNameEnum.CLICKED_LOGOUT_SIDENAV_MOBILE_WEB_NAVIGATION)
                }}>
                    <Image src="/images/header/logout.svg" alt="logout" className="w-10 h-10" />
                    <Text content='Logout' className="p5-medium text-gray-500" />
                </div>
            </div>
        </div>
    );
};

export default NavigationPanel;